.carousel {
  margin: 0 auto;
  position: relative;
  height: 250px;
  width: 250px;
  max-height: 300px;
  max-width: 300px;
}

.carousel_slide {
  opacity: 0;
  transition-duration: 1s ease;
  width: 100%;
}

.carousel_slide.active {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 1;
  transition-duration: 1s;
  /* transform: scale(1.08); */
  width: 100%;
}

.creation {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  z-index: 1;
  color: black;
  font-size: 2rem;
  mix-blend-mode: color-burn;
  height: 100%;
  width: 20%;
  padding: 0.5rem;
}

.carousel_button:hover {
  background: linear-gradient(
    to right,
    hsla(0, 0%, 0%, 0.431),
    hsla(0, 0%, 0%, 0.025)
  );
  color: white;
  mix-blend-mode: normal;
  filter: none;
  box-shadow: -5px 0 5px 2px  hsla(0, 0%, 0%, 0.025);
}

.carousel_forward {
  right: 0;
}

.carousel_forward:hover {
  background: linear-gradient(
    to right,
    hsla(0, 0%, 0%, 0.025),
    hsla(0, 0%, 0%, 0.431)
  );
}
