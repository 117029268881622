.testimonial_container {
  padding: 2rem;
  background-color: hsl(12deg 41% 30%);
  color: white;
}

.testimonials {
  margin: 0 auto;
  max-width: 820px;
}

.testimonial_label {
  margin: 2rem auto 4rem;
  width: 290px;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  border-bottom: 2px solid white;
}

.testimonial_item {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}

.avitar {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  margin: 0 auto;
  max-width: 250px;
  padding: 1rem 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
}

.review {
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
  font-size: 1.2rem;
}

@media (min-width: 820px) {
  .testimonials {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1000px;
  }

  .review {
    margin: 0;
    max-width: 380px;
  }
}

@media (min-width: 960px) {
  .testimonial_item {
    flex-direction: row;
    align-items: center;
    gap: 4rem;
  }

  .testimonial_item:nth-of-type(2) {
    flex-direction: row-reverse;
  }
}