.container {
  padding: 2rem;
  background-color: hsl(12deg 41% 31%);
}

.about_container {
  margin: 2rem auto;
  max-width: 820px;
}

.img_container {
  margin: 1rem auto 1rem;
  max-width: 600px;
}

.brandon {
  display: block;
  width: 100%;
}

.message_container {
  margin: 0 auto;
  text-align: justify;
  color: white;
  font-size: 1.2rem;
  max-width: 650px;
}

.message {
  padding-top: 1rem;
}

@media (min-width: 900px) {

  .img_container {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
    min-width: 400px;
    padding-top: 5px;
  }

  .message_container {
    text-align: justify;
    max-width: 900px;
  }
}
