.faq_container {
  padding: 4rem 2rem 4rem;
  background-color: hsl(12deg 41% 31%);
}

.faqs {
  margin: 0 auto;
  max-width: 820px;
}

